//export const WEB_SERVICES_URL = 'http://localhost:3000';
//export const WEB_SERVICES_URL = 'https://tranquil-mesa-29755.herokuapp.com'
export const WEB_SERVICES_URL = 'https://tranquil-mesa-29755-heroku-18.herokuapp.com'

export const DEVICE_SUPPORTED = "DEVICE_SUPPORTED";
export const DEVICE_NOT_SUPPORTED = "DEVICE_NOT_SUPPORTED";

export const INIT_IPC = 'INIT_IPC';
export const DEVICE_ARRIVED = 'DEVICE_ARRIVED';
export const DEVICE_APP_ARRIVED = 'DEVICE_APP_ARRIVED';
export const DEVICE_SBL_ARRIVED = 'DEVICE_SBL_ARRIVED';
export const DEVICE_REMOVED = 'DEVICE_REMOVED';
export const DEVICE_DATA_MCU = 'DEVICE_DATA_MCU';
export const READ_DEVICE_SETTINGS = 'READ_DEVICE_SETTINGS';
export const DEVICE_DATA_SETTINGS = 'DEVICE_DATA_SETTINGS';
export const UPDATE_SYSTEM_SETTINGS = 'UPDATE_SYSTEM_SETTINGS';
export const DEVICE_DATA_INPUT_SETTINGS = 'DEVICE_DATA_INPUT_SETTINGS';
export const SAVE_DEVICE_SETTINGS = 'SAVE_DEVICE_SETTINGS';
export const SUCCESS_SETTINGS_UPDATE = 'SUCCESS_SETTINGS_UPDATE';
export const FAILED_SETTINGS_UPDATE = 'FAILED_SETTINGS_UPDATE';
export const REQUEST_SBL = 'REQUEST_SBL';
export const DEVICE_USER_RESET = 'DEVICE_USER_RESET';
export const REQUEST_DATA_SETUP = 'REQUEST_DATA_SETUP';
export const REQUEST_DATA_SETUP_RESPONSE = 'REQUEST_DATA_SETUP_RESPONSE';
export const REQUEST_TRANSFER_START = 'REQUEST_TRANSFER_START';
export const REQUEST_TRANSFER_START_RESPONSE = 'REQUEST_TRANSFER_START_RESPONSE';
export const REQUEST_PACKET_SEND = 'REQUEST_PACKET_SEND';
export const REQUEST_PACKET_SEND_RESPONSE = 'REQUEST_PACKET_SEND_RESPONSE';
export const REQUEST_VALIDATE_BLOCK_SEND = 'REQUEST_VALIDATE_BLOCK_SEND';
export const REQUEST_VALIDATE_BLOCK_SEND_RESPONSE = 'REQUEST_VALIDATE_BLOCK_SEND_RESPONSE';
export const REQUEST_SECTOR_WRITE_SEND = 'REQUEST_SECTOR_WRITE_SEND';
export const REQUEST_SECTOR_WRITE_SEND_RESPONSE = 'REQUEST_SECTOR_WRITE_SEND_RESPONSE';
export const COMPLETE_UPDATE_REQUEST = 'COMPLETE_UPDATE_REQUEST';
export const START_OBD_PROGRAMMING = 'START_OBD_PROGRAMMING';
export const COMPLETE_OBD_PROGRAMMING = 'COMPLETE_OBD_PROGRAMMING';
export const READ_OSD_SETTINGS = 'READ_OSD_SETTINGS';
export const DEVICE_OSD_SETTINGS = 'DEVICE_OSD_SETTINGS';
export const SAVE_DEVICE_OSD_SETTINGS = 'SAVE_DEVICE_OSD_SETTINGS';
export const DEVICE_MFG_ID_RECIEVED = 'DEVICE_MFG_ID_RECIEVED';
export const REQUEST_REBOOT_AFTER_UPDATE = 'REQUEST_REBOOT_AFTER_UPDATE';
export const DEVICE_OBD_RESULT = 'DEVICE_OBD_RESULT';
export const DEVICE_OBD_RUNING = 'DEVICE_OBD_RUNING';
export const DEVICE_OBD_SUCCESS = 'DEVICE_OBD_SUCCESS';
export const DEVICE_OBD_FAILED = 'DEVICE_OBD_FAILED';
export const SET_CAN_FILTER = 'SET_CAN_FILTER';
export const SET_CAN_FILTER_SUCCESS = 'SET_CAN_FILTER_SUCCESS';
export const CAN_FILTER_NOT_SET = 'CAN_FILTER_NOT_SET';
export const CLEAR_CAN_FILTER = 'CLEAR_CAN_FILTER';
export const GET_CAN_FILTER_DATA = 'GET_CAN_FILTER_DATA';
export const GET_CAN_FILTER_DATA_RESULT = 'GET_CAN_FILTER_DATA_RESULT';
export const CLEAR_CAN_FILTER_RESULT = 'CLEAR_CAN_FILTER_RESULT';

export const FTP_LOAD_PROGRESS = 'FTP_LOAD_PROGRESS';
export const FTP_LOAD_SUCCESS = 'FTP_LOAD_SUCCESS';
export const FTP_LOAD_FAILURE = 'FTP_LOAD_FAILURE';
export const REQUEST_SBL_FOR_UPDATE = 'REQUEST_SBL_FOR_UPDATE';
export const START_SOFTWARE_UPDATE = 'START_SOFTWARE_UPDATE';


export const NO_DEVICE_STATUS = "NO_DEVICE_STATUS";
export const DEVICE_APP_STATUS = "DEVICE_APP_STATUS";
export const DEVICE_SBL_STATUS = "DEVICE_SBL_STATUS";

export const DEVICE_START_SECTOR = "DEVICE_START_SECTOR";

export const WAITING_FOR_SBL = "WAITING_FOR_SBL";
export const WAITING_START_SECTOR = "WAITING_START_SECTOR";
export const UPDATE_READY = "UPDATE_READY";
export const UPDATE_IN_PROGRESS = "UPDATE_IN_PROGRESS";
export const WAITING_FOR_APP_UPDATE = "WAITING_FOR_APP_UPDATE";


export const AFTER_UPDATE_ACTION = "AFTER_UPDATE_ACTION";
export const UPDATE_COMPLETED = "UPDATE_COMPLETED";

export const UPDATE_NOT_STARTED = "UPDATE_NOT_STARTED";
export const GET_START_SECTOR = 'GET_START_SECTOR';
export const SET_UP_TRANSFER = 'SET_UP_TRANSFER';
export const SET_UP_BULK_TRANSFER = 'SET_UP_BULK_TRANSFER';
export const START_TRANSFER = 'START_TRANSFER';
export const START_BULK_TRANSFER = 'START_BULK_TRANSFER';
export const BULK_UPDATE_IN_PROGRESS = 'BULK_UPDATE_IN_PROGRESS';
export const BULK_UPDATE_SECTOR_VALIDATE = 'BULK_UPDATE_SECTOR_VALIDATE';
export const BULK_SECTOR_WRITE = 'BULK_SECTOR_WRITE';
export const BULK_SECTOR_WRITE_RESULT = 'BULK_SECTOR_WRITE_RESULT';
export const BULK_SECTOR_WRITE_IN_PROGRESS = 'BULK_SECTOR_WRITE_IN_PROGRESS';
export const PACKET_SEND = 'PACKET_SEND';
export const BLOCK_VALIDATE = 'BLOCK_VALIDATE';
export const SECTOR_WRITE = 'SECTOR_WRITE';
export const TRANSFER_COMPLETED = 'TRANSFER_COMPLETED';
export const DB_UPDATE_COMPLETED = 'TRANSFER_COMPLETED';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const DISPLAY_UPDATE_ERROR  = 'DISPLAY_UPDATE_ERROR';
export const DISPLAY_UPDATE_SUCCESS = 'DISPLAY_UPDATE_SUCCESS';
export const DOWNLOAD_TEAM_VIEWER  = 'DOWNLOAD_TEAM_VIEWER';
export const DISPLAY_UPDATE_SETUP_ERROR  = 'DISPLAY_UPDATE_SETUP_ERROR';
export const FETCH_DEVICE_DB_DATA = 'FETCH_DEVICE_DB_DATA';
export const UPDATE_PROGRESS_REPORT = 'UPDATE_PROGRESS_REPORT';

export const OBD_NOT_STARTED = "OBD_NOT_STARTED";
export const OBD_IN_PROGRESS = "OBD_IN_PROGRESS";
export const OBD_COMPLETED = "OBD_COMPLETED";

export const FETCH_SOFTWARE = 'FETCH_SOFTWARE';
export const SET_SOFTWARE = 'SET_SOFTWARE';
export const SET_SOFTWARE_DESCRIPTION = 'SET_SOFTWARE_DESCRIPTION';
export const FETCH_SETTINGS_TYPE = 'FETCH_SETTINGS_TYPE';

export const FETCH_MAKE = 'FETCH_MAKE';
export const FETCH_MODEL = 'FETCH_MODEL';
export const FETCH_YEAR = 'FETCH_YEAR';
export const SET_YEAR = 'SET_YEAR';
export const SET_TRANSMISSION_TYPE = 'SET_TRANSMISSION_TYPE';
export const RESET_SEARCH = 'RESET_SEARCH';


export const WRITE_SUCESS = 44;
export const WRITE_FAILED = 55;
export const READ_SUCESS = 22;
export const READ_FAILED = 33;

export const OBD_NO_ERROR = 0;
export const OBD_RUNNING = 1;
export const OBD_SUCCESS = 2;
export const OBD_FAILED = 3;

export const BLOCK_SIZE = 512;
//export const SMALL_SECTOR_SIZE = 8192;
export const SMALL_SECTOR_SIZE = 4096;
export const HID_TRANSFER_SIZE = 64;

export const SECTOR_WRITE_SUCCESS = 2;
export const SECTOR_WRITE_ERROR = 3;

export const BL_WRITE_SUCCESS = 2;
export const BL_WRITE_FAILED = 3;

export const HIDE_MODAL = 'HIDE_MODAL';
export const ICON_INFO = 'info blue'
export const ICON_ERROR = 'exclamation red'

